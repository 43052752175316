class AppEvent {
  constructor(data = {}) {
    this._data = data;
  }

  static getName() {
    throw new Error('Missing name');
  }

  getData() {
    return this._data;
  }
}

export default AppEvent;
