import EventEmitter from 'eventemitter3';
import AppEvent from './Events/AppEvent';

class EventBus {
  constructor() {
    this._eventEmitter = new EventEmitter();
  }

  subscribe(event, callback = () => {}) {
    if (!(new event() instanceof AppEvent)) {
      throw new Error('Invalid event');
    }

    const eventName = event.getName();

    const _callback = (event) => {
      callback(event.getData());
    };

    this._eventEmitter.on(eventName, _callback);

    return () => {
      this._eventEmitter.removeListener(eventName, _callback);
    };
  }

  publish(event) {
    if (!(event instanceof AppEvent)) {
      throw new Error('Invalid event');
    }

    const EventClass = event.constructor;
    this._eventEmitter.emit(EventClass.getName(), event);

    const globalEvent = new Event(EventClass.getName());
    document.dispatchEvent(globalEvent);
  }
}

export default EventBus;
