import { animate, inView } from 'motion';
import PostListUpdateEvent from '../List/Events/PostListUpdateEvent';
export default class FadeIn {
  constructor(selector) {
    this.selector = selector;
    this.items = document.querySelectorAll(selector);
    this.stop = null;

    if (!this.items.length) return;

    this.init();
    this.setupEventListeners();
  }

  init() {
    this.stop = inView(
      this.items,
      (element) => {
        animate(
          element,
          { opacity: [0, 1] },
          {
            duration: 0.8,
            easing: [0.17, 0.55, 0.55, 1],
          },
        );
      },
      {
        amount: 0.25,
      },
    );
  }

  setupEventListeners() {
    document.addEventListener(
      PostListUpdateEvent.getName(),
      this.refresh.bind(this),
    );
  }

  refresh() {
    if (this.stop) {
      this.stop();
    }
    this.items = document.querySelectorAll(this.selector);
    this.init();
  }
}
