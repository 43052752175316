import { DomFetcher } from './DomFetcher';
import { NetworkService } from './NetworkService';
import { ListHandler } from './ListHandler';
import { FormHandler } from './FormHandler';

const networkService = new NetworkService();
const domFetcher = new DomFetcher(networkService);

const create = (listElement, loadingCssClass, errorCssClass) => {
  const handlers = [];

  handlers.push(new FormHandler(listElement));

  const listHandler = new ListHandler(
    listElement,
    domFetcher,
    handlers,
    loadingCssClass,
    errorCssClass,
  );

  listHandler.init();

  return listHandler;
};

export { create, DomFetcher, NetworkService, ListHandler, FormHandler };
