import EventBus from './EventBus';
import PostListUpdateEvent from './Events/PostListUpdateEvent';

const DATA_ATTR_LIST_ID_ATTR = 'data-list';
const DATA_ATTR_LIST_CONTENT_ATTR = 'data-list-content';
const DATA_ATTR_LIST_PAGINATION_ATTR = 'data-list-pagination';
const LIST_LOADING_CLASS = 'is-fetching';
const LIST_ERROR_CLASS = 'has-error';
const AJAX_LOADER_CLASS = 'ajax-loader';
export class ListHandler {
  constructor(
    listElement,
    domFetcher,
    actionHandlers = [],
    loadingCssClass,
    errorCssClass,
  ) {
    this._element = listElement;
    this._elementId = listElement.getAttribute(ListHandler.getListAttr());
    this._domFetcher = domFetcher;
    this._actionHandlers = actionHandlers;
    this._loadingCssClass = loadingCssClass;
    this._errorCssClass = errorCssClass;

    this._eventBus = new EventBus();
  }

  static getListAttr() {
    return DATA_ATTR_LIST_ID_ATTR;
  }
  static getListContentAttr() {
    return DATA_ATTR_LIST_CONTENT_ATTR;
  }
  static getListPaginationAttr() {
    return DATA_ATTR_LIST_PAGINATION_ATTR;
  }

  static getListLoadingClass() {
    return LIST_LOADING_CLASS;
  }

  static getListErrorClass() {
    return LIST_ERROR_CLASS;
  }

  onPostUpdate(fn = () => {}) {
    return this._eventBus.subscribe(PostListUpdateEvent, fn);
  }

  init() {
    this._actionHandlers.forEach((handler) => {
      handler.setActionCallback((url) => {
        this.load(url);
      });

      handler.init();
    });

    this.createLiveRegion(ListHandler.getListContentAttr());

    this.createLoader();
  }

  createLoader() {
    this._loaderElement = document.createElement('div');
    this._loaderElement.className = AJAX_LOADER_CLASS;
    this._loaderElement.style.display = 'none';
    document.body.appendChild(this._loaderElement);
  }

  replaceElementByAttribute(newElement, attribute) {
    const replacement = newElement.querySelector(`[${attribute}]`);
    const old = this._element.querySelector(`[${attribute}]`);

    if (old && replacement) {
      old.replaceWith(replacement);
    }
  }

  async load(url) {
    this._loaderElement.style.display = 'block';

    this._element.classList.add(this._loadingCssClass);

    const success = await this._renderFromUrl(url);

    this._element.classList.remove(this._loadingCssClass);

    this._loaderElement.style.display = 'none';

    if (!success) {
      this._element.classList.add(this._errorCssClass);
    }

    this._eventBus.publish(new PostListUpdateEvent({ success: success }));
  }

  async _renderFromUrl(url) {
    let dom;

    try {
      dom = await this._domFetcher.fetch(url);
    } catch (e) {
      console.error(e);
      return false;
    }

    const newElement = dom.body.querySelector(
      `[${ListHandler.getListAttr()}='${this._elementId}']`,
    );

    if (!newElement) {
      return false;
    }

    history.pushState({}, null, url.toString());

    this.replaceElementByAttribute(
      newElement,
      ListHandler.getListContentAttr(),
    );
    this.replaceElementByAttribute(
      newElement,
      ListHandler.getListPaginationAttr(),
    );

    return true;
  }

  createLiveRegion(listAttr) {
    const list = this._element.querySelector(`[${listAttr}]`);
    list.setAttribute('role', 'region');
    list.setAttribute('aria-live', 'polite');
  }
}
