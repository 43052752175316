import { getMousePosition } from './Utils/getMousePosition';

const PARENT_SELECTOR = '[data-shapes-parent]';
const SHAPE_SELECTOR = '[data-shapes]';
const IMAGE_SELECTOR = '[data-image-shape]';
const VIDEO_SELECTOR = '.video';

export default class Shapes {
  constructor(parent) {
    this.parent = parent;
    this.media =
      parent.querySelector(IMAGE_SELECTOR) ??
      parent.querySelector(VIDEO_SELECTOR) ??
      null;
    const shapesContainer = parent.querySelector(SHAPE_SELECTOR);
    if (!shapesContainer) return;

    this.shapes = [...shapesContainer.children, this.media];

    this.mousePosition = { x: 0, y: 0 };
    this.factors = Array.from({ length: this.shapes.length }, this.getFactor);

    // Throttle mouse move events using requestAnimationFrame
    this.isTicking = false;
    document.addEventListener('mousemove', this.handleMouseMove.bind(this));
  }

  static getParentSelector() {
    return PARENT_SELECTOR;
  }

  updateShapePosition(shape, index) {
    if (!shape) return;
    const factor = this.factors[index];
    const { x, y } = this.mousePosition;
    const { clientWidth, clientHeight } = this.parent;
    const xPosition = x / clientWidth;
    const yPosition = y / clientHeight;
    const xShift = (xPosition - 0.5) * 2;
    const yShift = (yPosition - 0.5) * 2;
    const xTranslate = xShift * factor * (factor % 2 === 0 ? -1 : 1);
    const yTranslate = yShift * factor * (-factor % 2 === 0 ? -1 : 1);

    shape.style.transform = `translate(${xTranslate.toFixed(2)}px, ${yTranslate.toFixed(2)}px)`;
  }

  handleMouseMove(event) {
    this.mousePosition = getMousePosition(event);
    if (!this.isTicking) {
      window.requestAnimationFrame(() => {
        this.shapes.forEach((shape, index) => {
          this.updateShapePosition(shape, index);
        });
        this.isTicking = false;
      });
      this.isTicking = true;
    }
  }

  getFactor() {
    const min = 2;
    const max = 10;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
