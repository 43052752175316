const VIDEO_DATA = 'data-lazy';
const VIDEO_SELECTOR = `video[${VIDEO_DATA}]`;
const VIDEO_SRC = 'data-src';

export default class VideoLazyLoader {
  constructor() {
    this.lazyVideos = Array.from(document.querySelectorAll(VIDEO_SELECTOR));
    this.init();
  }

  init() {
    if ('IntersectionObserver' in window) {
      this.lazyVideoObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((video) => {
          if (video.isIntersecting) {
            this.loadVideos(video);
          }
        });
      });
      this.lazyVideos.forEach((lazyVideo) => {
        this.lazyVideoObserver.observe(lazyVideo);
      });
    } else {
      this.lazyVideos.forEach((lazyVideo) => {
        this.loadVideos(lazyVideo);
      });
    }
  }

  loadVideos(element) {
    if (!element) return;

    const video = element?.target ?? element;
    const sources = video.children;

    for (let source in sources) {
      const videoSource = sources[source];
      if (
        typeof videoSource.tagName === 'string' &&
        videoSource.tagName === 'SOURCE'
      ) {
        videoSource.src = videoSource.dataset.src;
        videoSource.removeAttribute(VIDEO_SRC);
      }
    }
    video.load();
    video.removeAttribute(VIDEO_DATA);
    if (element.target) {
      this.lazyVideoObserver.unobserve(video);
    }
  }
}
