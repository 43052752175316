import navigationAndSearch from './Global/navigationAndSearch';
import FormChangeSubmitHandler from './FormChangeSubmitHandler';
import VideoLazyLoader from './Global/VideoLazyLoader';
import { create as listHandlerFactory, ListHandler } from './List';
import Shapes from './Shapes';
import SmoothScroll from './SmoothScroll';
import FadeIn from './Animations/FadeIn';

const formChangeSubmitHandler = new FormChangeSubmitHandler();

document.addEventListener('DOMContentLoaded', () => {
  document.documentElement.classList.remove('no-js');
  document.documentElement.classList.add('has-js');

  new SmoothScroll();
  new FadeIn('.imageWrapper > .image');
  navigationAndSearch();
  document.querySelectorAll(Shapes.getParentSelector()).forEach((element) => {
    new Shapes(element);
  });
  new VideoLazyLoader();
  formChangeSubmitHandler.init();

  document
    .querySelectorAll(`[${ListHandler.getListAttr()}]`)
    .forEach((list) => {
      listHandlerFactory(
        list,
        ListHandler.getListLoadingClass(),
        ListHandler.getListErrorClass(),
      );
    });
});
