import ActionHandler from './ActionHandler';

const DATA_ATTR_LIST_FORM_ATTR = 'data-list-form';
const FORM_ATTR_ACTION = 'action';

export class FormHandler extends ActionHandler {
  constructor(listElement) {
    super(listElement);
    this._form = listElement.querySelector(`[${DATA_ATTR_LIST_FORM_ATTR}]`);
  }

  setupEvents() {
    if (!this._form) return;

    this._form.addEventListener('submit', (ev) => {
      ev.preventDefault();
      this.onActionEvent();
    });
  }

  addLoader() {
    // Not implemented
  }

  getUrl() {
    const formData = new FormData(this._form);
    const searchParams = new URLSearchParams(formData);
    let urlString = this._form.getAttribute(FORM_ATTR_ACTION);

    formData.forEach((value, key) => {
      if (value === '') {
        searchParams.delete(key);
      }
    });

    if (!urlString) {
      urlString = window.location.href;
    }

    const url = new URL(urlString);
    url.search = searchParams.toString(); // Replace querystring with formdata
    url.hash = window.location.hash;

    return url;
  }
}
