export default class ActionHandler {
  constructor(listElement) {
    this._element = listElement;
    this._actionCallback = () => {};
  }

  setActionCallback(callback) {
    this._actionCallback = callback;
  }

  onActionEvent() {
    const url = this.getUrl();

    this._actionCallback(url);
  }

  scrollToListTop() {
    this._element.scrollIntoView({ behavior: 'smooth' });
  }

  init() {
    this.setupEvents();
    this.addLoader();
  }

  setupEvents() {
    // abstract
  }

  addLoader() {
    // abstract
  }

  getUrl() {
    // abstract
  }
}
