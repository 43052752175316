const hideMobileKeyboardOnReturn = (element) => {
  element.addEventListener('keyup', (ev) => {
    const key = ev.code || ev.keyCode;
    if (key === 'Enter' || key === 13) {
      element.blur();
    }
  });
};

export default hideMobileKeyboardOnReturn;
