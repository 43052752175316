import debounce from './Utils/debounce';
import hideMobileKeyboardOnReturn from './Utils/hideMobileKeyboardOnReturn';

const FORM = 'form';
const INPUT_SELECTOR = '[data-form-change-submit]';
const DEBOUNCE_DELAY = 300;
const SEARCH_SELECTOR = '[type="search"]';

export default class FormChangeSubmitHandler {
  constructor(inputSelector = INPUT_SELECTOR) {
    this._inputSelector = inputSelector;
    this.debouncedSubmitOnChange = debounce(
      this.submitOnChange.bind(this),
      DEBOUNCE_DELAY,
    );
    this.searchInputs = document.querySelectorAll(SEARCH_SELECTOR);
  }

  init() {
    document.body.addEventListener('change', (ev) => {
      if (ev.target.matches(this._inputSelector)) {
        this.debouncedSubmitOnChange(ev, 0);
      }
    });
    document.body.addEventListener('input', (ev) => {
      if (ev.target.matches(this._inputSelector)) {
        this.debouncedSubmitOnChange(ev);
      }
    });

    this.searchInputs.forEach((input) => {
      hideMobileKeyboardOnReturn(input);
    });
  }

  submitOnChange(ev) {
    ev.preventDefault();
    const form = ev.target.closest(FORM);
    if (form) {
      form.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true }),
      );
    }
  }
}
