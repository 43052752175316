const HTTP_VERB_GET = 'GET';

export class NetworkService {
  async _makeRequest(verb, url) {
    const response = await fetch(url, {
      method: verb,
      referrerPolicy: 'no-referrer',
    });

    return response;
  }

  async get(url) {
    return await this._makeRequest(HTTP_VERB_GET, url);
  }
}
