const HTML_MIME = 'text/html';

export class DomFetcher {
  constructor(networkService) {
    this._networkService = networkService;
  }

  _parseDocument(htmlDocumentString) {
    const parser = new DOMParser();
    const document = parser.parseFromString(htmlDocumentString, HTML_MIME);

    return document;
  }

  async fetch(url) {
    const result = await this._networkService.get(url);
    const body = await result.text();
    const dom = this._parseDocument(body);

    return dom;
  }
}
