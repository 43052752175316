import isMobileView from './../Utils/viewportHelper';
import generateUUID from './../Utils/generateUUID';

const ACTIVE_CLASS = 'isActive';
const MOBILE_MENU_ACTIVE_CLASS = 'mainMenuActive';
const MENU_ICON_ID = 'iconHamburger';
const MENU_CLOSE_ICON_ID = 'iconClose';
const MAIN_NAV_ACTIVE_CLASS = 'mainNavigationActive';

const BRAKPOINT_CHANGE_CLASS = 'breakpointChange';

export default function navigationAndSearch() {
  const menuButtons = document.querySelectorAll(
    '.menuButtonItem .menuButtonToggle',
  );
  const submenuMap = new Map();
  let menuToggle;
  let searchToggle;
  let svgUseElement;
  let lastFocusedElement;

  async function handleViewportChange(event) {
    const { wasMobile, isMobile } = event.detail;

    /* Hide menues until discret animations have finished */
    if (isMobile) {
      document.body.classList.add(BRAKPOINT_CHANGE_CLASS);
      setTimeout(() => {
        document.body.classList.remove(BRAKPOINT_CHANGE_CLASS);
      }, 800);
    }

    if (wasMobile !== isMobile) {
      await closeAllSubmenus(true); // Use the forceClose flag
      closeSearch();
      closeMobileMenu();
    }
  }

  async function closeAllSubmenus(forceClose = false) {
    const closePromises = Array.from(menuButtons).map((button) =>
      closeMenu(button, forceClose),
    );
    await Promise.all(closePromises);
  }

  function closeMenu(button, forceClose = false) {
    return new Promise((resolve, reject) => {
      const submenu = submenuMap.get(button);

      if (!submenu) {
        resolve();
        return;
      }

      if (forceClose) {
        submenu.classList.remove(ACTIVE_CLASS);
        button.setAttribute('aria-expanded', 'false');
        document.body.classList.remove(MAIN_NAV_ACTIVE_CLASS);
        resolve();
        return;
      }

      if (submenu.classList.contains(ACTIVE_CLASS)) {
        submenu.classList.remove(ACTIVE_CLASS);
        resolve();
        document.body.classList.remove(MAIN_NAV_ACTIVE_CLASS);
        button.setAttribute('aria-expanded', 'false');
      } else {
        resolve();
      }
    });
  }

  function openMenu(button) {
    const submenu = submenuMap.get(button);
    submenu.classList.add(ACTIVE_CLASS);
    button.setAttribute('aria-expanded', 'true');
    document.body.classList.add(MAIN_NAV_ACTIVE_CLASS);
  }

  async function toggleMenu(event) {
    const button = event.target.closest('.menuButtonToggle');
    const parentItem = button.closest('.menuButtonItem');
    const submenu = parentItem.querySelector('.menuSubmenuItem');

    if (submenu) {
      if (submenu.classList.contains(ACTIVE_CLASS)) {
        await closeMenu(button);
      } else {
        if (!isMobileView()) {
          // Check if not in mobile view
          await closeAllSubmenus();
        }
        openMenu(button);
      }
    }
  }

  function initializeMenuButtons() {
    menuButtons.forEach((button) => {
      const parentItem = button.closest('.menuButtonItem');
      const submenu = parentItem.querySelector('.menuSubmenuItem');

      if (submenu) {
        // Generate random ID for each button and enhance the menu with aria-controls and aria-expanded attributes
        const submenuID = `submenu-${generateUUID()}`;
        submenu.setAttribute('id', submenuID);

        // Set up aria-controls on the button
        button.setAttribute('aria-controls', submenuID);
        // Set up aria-expanded on the button
        button.setAttribute('aria-expanded', 'false');

        // Save submenu in the map for later use
        submenuMap.set(button, submenu);
      }
    });
  }

  function setupMenuEventListeners() {
    menuButtons.forEach((button) => {
      button.addEventListener('click', toggleMenu);
    });

    document.addEventListener('click', (event) => {
      if (!isMobileView()) {
        const isClickInsideMenu = Array.from(menuButtons).some((button) =>
          button.parentNode.contains(event.target),
        );
        if (!isClickInsideMenu) {
          closeAllSubmenus();
        }
      }
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        closeAllSubmenus();
      }
    });
  }

  // Handle Search
  function openSearch(e) {
    lastFocusedElement = document.activeElement;
    const input = e.target
      .closest('.searchToggle')
      .parentNode.querySelector('input');

    searchToggle.setAttribute('aria-expanded', 'true');

    const newLabel = searchToggle.getAttribute('data-close-label');
    searchToggle.setAttribute('aria-label', newLabel);

    svgUseElement.setAttribute('xlink:href', `#${MENU_CLOSE_ICON_ID}`);

    document.body.classList.add('searchActive');

    if (input) {
      input.focus();
      setTimeout(() => {
        input.scrollIntoView({
          behavior: 'smooth', // or 'auto'
          block: 'center', // or 'start', 'end', 'nearest'
          inline: 'nearest',
        });
      }, 0);
    }
  }

  function closeSearch() {
    searchToggle.setAttribute('aria-expanded', 'false');

    const newLabel = searchToggle.getAttribute('data-open-label');
    searchToggle.setAttribute('aria-label', newLabel);

    svgUseElement.setAttribute('xlink:href', '#iconSearch');

    document.body.classList.remove('searchActive');

    if (lastFocusedElement) {
      lastFocusedElement.focus();
    }
  }

  function toggleSearch(event) {
    if (event) event.stopPropagation();

    const isExpanded = searchToggle.getAttribute('aria-expanded') === 'true';

    if (isExpanded) {
      closeSearch();
    } else {
      closeAllSubmenus();
      openSearch(event);
    }
  }

  function setupSearchEventListeners() {
    searchToggle = document.querySelector('.searchToggle');
    svgUseElement = searchToggle?.querySelector('.icon use');
    const desktopSearch = document
      .querySelector('#headerSearch')
      .closest('.searchBar');

    if (!searchToggle || !desktopSearch || !svgUseElement) {
      return;
    }

    searchToggle.addEventListener('click', toggleSearch);

    document.addEventListener('click', (event) => {
      const isClickInside =
        desktopSearch.contains(event.target) ||
        searchToggle.contains(event.target);

      if (
        !isClickInside &&
        searchToggle.getAttribute('aria-expanded') === 'true'
      ) {
        closeSearch();
      }
    });
  }

  // Handle Mobile menu
  function openMobileMenu() {
    menuToggle.setAttribute('aria-expanded', 'true');

    const svgUseElement = menuToggle.querySelector('.icon use');
    if (svgUseElement) {
      svgUseElement.setAttribute('xlink:href', `#${MENU_CLOSE_ICON_ID}`);
    }

    document.body.classList.add(MOBILE_MENU_ACTIVE_CLASS);
  }

  function closeMobileMenu() {
    menuToggle.setAttribute('aria-expanded', 'false');

    const svgUseElement = menuToggle.querySelector('.icon use');
    if (svgUseElement) {
      svgUseElement.setAttribute('xlink:href', `#${MENU_ICON_ID}`);
    }

    document.body.classList.remove(MOBILE_MENU_ACTIVE_CLASS);
  }

  function toggleMobileMenu(event) {
    const isExpanded = menuToggle.getAttribute('aria-expanded') === 'true';

    if (isExpanded) {
      closeMobileMenu();
    } else {
      openMobileMenu();
    }
  }

  function setupMobileMenuEventListeners() {
    menuToggle = document.querySelector('.menuToggle');
    if (menuToggle) {
      menuToggle.addEventListener('click', toggleMobileMenu);
    }
  }

  window.addEventListener('viewportChange', handleViewportChange);

  // Initialize the menu buttons
  initializeMenuButtons();
  setupMenuEventListeners();
  setupSearchEventListeners();
  setupMobileMenuEventListeners();
}
